import Container from 'components/Container'
import UpdateAddressForm from 'components/customer/UpdateAddressForm'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function Update() {
  return (
    <Container css={{ position: 'relative' }}>
      <Helmet title='Edit Address'>
        <meta name='robots' content='noindex' />
      </Helmet>
      <UpdateAddressForm />
    </Container>
  )
}
