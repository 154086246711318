import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const Select = ({ styles, size, children, ...props }) => (
  <select
    css={{
      fontSize: 16,
      outline: 'none',
      display: 'block',
      width: '100%',
      height: size === 'sm' ? 48 : 64,
      padding: '0 1rem',
      transition: theme.globalTransition,
      ':hover': {
        border: `1px solid ${theme.colors.b40}`,
      },
      ':focus': {
        border: `1px solid ${theme.colors.broccoli}`,
        outline: 'none',
      },
      ...styles,
    }}
    {...props}
  >
    {children}
  </select>
)

Select.propTypes = {
  styles: PropTypes.object,
}

export default Select
